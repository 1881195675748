import React, { ReactElement } from "react";
import Form from "@components/shared/form";
import styles from "./styles.module.scss";
import colors from "@styles/variables/storagecontainerpricesVariables.module.scss";

export default function MainBanner(): ReactElement {
    return (
        <div className={styles["mainBannerWrapper"]}>
            <div className={styles["main-banner"]}>
                <Form
                    classes={{
                        formClassName: styles["form"],
                        stepTitleClassName: styles["step-title"],
                        stepDescriptionClassName: styles["step-description"],
                        formButtonClassName: styles["form-button"],
                        formFieldClassName: styles["form-field"],
                        formStepInnerClassName: styles["steps-inner"],
                        fieldsClassName: {
                            radio: styles["radio-field"],
                        },
                        fieldsWrapperClassName: styles["fields-wrapper"],
                        tcpaClassName: styles["tcpa"],
                        labelAsTitleClassName: styles["label-as-step-title"],
                        stepClassName: styles["step"],
                        formProgressbarClassName: styles["progress-background"],
                    }}
                    colors={{
                        primaryColor: colors.defaultButtonColor,
                        progressBar: colors.defaultProgressColor,
                    }}
                    showProgress={true}
                />
            </div>
        </div>
    );
}
