import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";
import { useDomainContext } from "@hooks/useDomainContext";
import StorageContainerWrapper from "@components/layouts/storagecontainerpricesWrapper";
import TopSection from "@components/storagecontainerprices/topSection";
import styles from "./style.module.scss";

const OffersList = dynamic(() => import("@components/shared/offersList"));

const Benefits = dynamic(
    () => import("@components/storagecontainerprices/benefits"),
);
export default function StorageContainerPricesHome(): ReactElement {
    const { domain } = useDomainContext();

    return (
        <StorageContainerWrapper>
            <TopSection />
            <div className={`px-4vw lg:px-4 ${styles["mxWrapper"]}`}>
                <section>
                    <OffersList offers={domain?.defaultOffers} />
                </section>
                <Benefits />
            </div>
        </StorageContainerWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
