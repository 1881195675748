import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Header from "@components/shared/header";
import MainBanner from "@components/storagecontainerprices/mainBanner";
import MainImage from "@components/storagecontainerprices/mainImage";
import colors from "@styles/variables/storagecontainerpricesVariables.module.scss";
import StorageContainerLogo from "../logo";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import { finalShowForm } from "@lib/sharedUtils";
import TextBlockOffer from "@components/shared/textBlockOffer/TextBlockOffer";
export default function TopSection(): ReactElement {
    const router = useRouter();
    const { domain } = useDomainContext();
    const isFormDisplayed = finalShowForm(
        router.query.sl as string,
        domain.showForm,
        domain.defaultOffers,
    );
    return (
        <div style={{ background: "#f4f4f4" }}>
            <div className={` ${styles["mxWrapper"]}`}>
                <Header
                    slogan="find the best storage container prices"
                    textColor={colors.offerCallNumber}
                    backGroundColor="#f4f4f4"
                >
                    <StorageContainerLogo />
                </Header>
            </div>
            {isFormDisplayed ? (
                <div className={`${styles["topSection"]}`}>
                    <div className={` ${styles["mxWrapper"]}`}>
                        <main className={`px-4vw lg:px-4`}>
                            <div
                                className={`flex flex-col-reverse md:flex-row items-center justify-between`}
                            >
                                <MainImage />
                                <MainBanner />
                            </div>
                        </main>
                    </div>
                </div>
            ) : (
                <TextBlockOffer />
            )}
        </div>
    );
}
